//global variable
$font-weight: normal;
$font-family: 'Muli';
//$font-family: Microsoft Sans Serif;
$font-size: 14px;
$white-color: #fff;
$black-color: #000000;

.dropdown-menu {
  max-height: 220px;
  min-width: 400px !important;
  overflow: auto;
}

.checkboxList {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  width: 300px;
  height: 100px;
  overflow-y: scroll;
}

.redBorderTxt {
  color: red;
  /*background-color:  #0081c2;*/
  background-color: transparent;
  border: 1px solid;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  font-family: $font-family;
  font-size: 13px;
  width: inherit;
  font-weight: bold;
  padding: 3px 3px 3px 3px;
}

.yellowBorderTxt {
  color: red;
  background-color: rgba(240, 255, 0, 0.33);
  border: 1px solid;
  border-color: red;
  border-style: solid;
  border-width: 1px;
  font-family: $font-family;
  font-size: 10px;
  width: inherit;
  font-weight: bold;
  padding: 3px 3px 3px 3px;
}
.redText {
  color: red;
  background-color: #ffcccc;
  font-family: $font-family;
  font-size: 10px;
  width: inherit;
  font-weight: bold;
  padding: 3px 3px 3px 3px;
}
.greyOutTextBox {
  background-color: #ebebe4 !important;
}
.img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

.clear {
  clear: both;
}

.tab-padding {
  padding: 12px;
}

.MuiDataGrid-main {
  font-size: 13px;
}

.MuiAutocomplete-root .MuiInputBase-root {
  font-size: 13px;
  padding: 2px;
  background: white;
}

.textHeaderAlign {
  text-align: center !important;
}

.textDateAlign {
  text-align: right !important;
}

.textNumericAlign {
  text-align: right !important;
}

.strike {
  text-decoration: line-through;
}
.checkbox-inline,
.radio-inline {
  vertical-align: top;
}
